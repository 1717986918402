var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-layout',[_c('v-col',{attrs:{"sm":"12"}},[_c('h1',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.$t('c:booking:Planning'))+" ")]),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.slots,"loading":_vm.loading,"disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.resourceId",fn:function(ref){
var item = ref.item;
return [_c('v-select',{attrs:{"items":_vm.resources,"item-text":"name","item-value":"id"},model:{value:(item.resourceId),callback:function ($$v) {_vm.$set(item, "resourceId", $$v)},expression:"item.resourceId"}})]}},{key:"item.scheduleId",fn:function(ref){
var item = ref.item;
return [_c('v-select',{attrs:{"items":_vm.schedules,"item-text":"name","item-value":"id"},model:{value:(item.scheduleId),callback:function ($$v) {_vm.$set(item, "scheduleId", $$v)},expression:"item.scheduleId"}})]}},{key:"item.placeId",fn:function(ref){
var item = ref.item;
return [_c('v-select',{attrs:{"items":_vm.places,"item-text":"name","item-value":"id"},model:{value:(item.placeId),callback:function ($$v) {_vm.$set(item, "placeId", $$v)},expression:"item.placeId"}})]}},{key:"item.serviceId",fn:function(ref){
var item = ref.item;
return [_c('v-select',{attrs:{"items":_vm.servicesForSlots,"item-text":"name","item-value":"id"},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_vm._v(" "+_vm._s(data.item.name)+" "+_vm._s(data.item.status === 'Deleted' ? ' (' + _vm.$t('DELETED') + ')' : '')+" ")]}},{key:"item",fn:function(data){return [_vm._v(" "+_vm._s(data.item.name)+" - #"+_vm._s(data.item.id)+" "+_vm._s(data.item.status === 'Deleted' ? ' (' + _vm.$t('DELETED') + ')' : '')+" ")]}}],null,true),model:{value:(item.serviceId),callback:function ($$v) {_vm.$set(item, "serviceId", $$v)},expression:"item.serviceId"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('a',{on:{"click":function($event){return _vm.duplicateSlot(item)}}},[_vm._v(_vm._s(_vm.$t('Duplicate')))]),_c('a',{on:{"click":function($event){return _vm.removeSlot(item)}}},[_vm._v(_vm._s(_vm.$t('Delete')))])]}}])}),_c('v-layout',[_c('v-col',[_c('v-btn',{attrs:{"disabled":_vm.loading,"loading":_vm.loading,"color":"primary"},on:{"click":function($event){return _vm.save()}}},[_vm._v(_vm._s(_vm.$t('Save')))]),_c('v-btn',{on:{"click":function($event){return _vm.back()}}},[_vm._v(_vm._s(_vm.$t('Back')))]),_c('v-btn',{attrs:{"disabled":_vm.loading,"color":"green","dark":""},on:{"click":function($event){return _vm.addSlot()}}},[_vm._v(_vm._s(_vm.$t('c:booking-planning:Add row')))])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }