


















































import Vue from 'vue'
import Component from 'vue-class-component'
import { vxm } from '@/store'
import { Watch } from 'vue-property-decorator'

class Slot {
  id: number
  scheduleId: number
  serviceId: number
  resourceId: number
  placeId: number

  constructor(data = null) {
    if (!data) {
      data = {}
    }
    this.id = data.id || null
    this.scheduleId = data.scheduleId || null
    this.serviceId = data.serviceId || null
    this.resourceId = data.resourceId || null
    this.placeId = data.placeId || null
  }
}

@Component({})
export default class Main extends Vue {
  headers = []

  places = []
  resources = []
  services = []
  schedules = []
  slots = []
  saving = false

  initializedCount = 0

  mounted() {
    this.headers = [
      {
        text: this.$t('Service'),
        value: 'serviceId',
      },
      {
        text: this.$t('Resource'),
        value: 'resourceId',
      },
      {
        text: this.$t('Schedule'),
        value: 'scheduleId',
      },
      {
        text: this.$t('Place'),
        value: 'placeId',
      },
      {
        text: this.$t('Actions'),
        value: 'actions',
      },
    ]

    this.load()
  }

  load() {
    this.initializedCount = 0

    const getData = (urlPart, callback) => {
      this.$axios
        .get('/v4/site/calendars/' + this.$route.params.calendarId + '/' + urlPart)
        .then((response) => {
          callback(response.data.data)
          this.initializedCount++
        })
        .catch((err) => {
          if (err.response.data && err.response.data.error) {
            vxm.alert.error({
              content: err.response.data.error.message,
              title: this.$t('c:common:Error') as string,
            })
          }
        })
    }

    getData('places?perPage=500&sortBy=name', (data) => {
      this.places = data
    })
    getData('resources?perPage=500&sortBy=name', (data) => {
      this.resources = data
    })
    getData(
      'services?perPage=500&sortBy=name&searchToFilters=1&showDeletedAndActive=1&search=' + encodeURIComponent('type:=1'),
      (data) => {
        this.services = data
      },
    )
    getData('schedules?perPage=500&sortBy=name', (data) => {
      this.schedules = data
    })

    getData('slots?perPage=500', (data) => {
      this.setSlots(data)
    })
  }

  /**
   * Get active services,
   * but also any deleted services that may be in use,
   * (so we avoid them disappearing when saving)
   */
  private get servicesForSlots() {
    const serviceIds = {}
    for (let i = 0; i < this.slots.length; i++) {
      serviceIds[this.slots[i].serviceId] = 1
    }
    const result = []
    for (let i = 0; i < this.services.length; i++) {
      const s = this.services[i]
      const isDeleted = s.status === 'Deleted'
      if (!isDeleted || serviceIds[s.id]) {
        result.push(s)
      }
    }
    return result
  }

  get loading() {
    return this.saving || this.initializedCount < 5
  }

  @Watch('$route', { deep: true })
  onRouteChange(newRoute, oldRoute) {
    if (newRoute === oldRoute) {
      return
    }
    if (newRoute.params.calendarId === oldRoute.params.calendarId) {
      return
    }
    this.load()
  }

  setSlots(data) {
    const slots = []
    data.forEach((s) => {
      slots.push(new Slot(s))
    })
    this.slots = slots
  }

  addSlot() {
    this.slots.push(new Slot())
  }

  duplicateSlot(item) {
    const slot = new Slot(item)
    slot.id = null
    this.slots.push(slot)
  }

  removeSlot(item) {
    const index = this.slots.indexOf(item)
    if (index === -1) {
      return
    }
    this.slots.splice(index, 1)
  }

  back() {
    this.$router.back()
  }

  save() {
    this.saving = true
    const url = '/v4/site/calendars/' + this.$route.params.calendarId + '/slots'
    this.$axios
      .put(url, { slots: this.slots })
      .then((_response) => {
        this.saving = false
        // this.setSlots(response.data.data) // something not right with setting ids, ref. backend log, it recreates stuff after duplicate row and multiple save, quickfix with load() instead for now
        this.load()
      })
      .catch((err) => {
        this.saving = false
        if (err.response.data && err.response.data.error) {
          vxm.alert.error({
            content: err.response.data.error.message,
            title: this.$t('c:common:Error') as string,
          })
        }
      })
  }
}
